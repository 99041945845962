import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import menuCardStyleMixin from '@/utils/mixins/styling/menuCardStyle.mixin.js'
import { mapState, mapGetters, mapMutations } from 'vuex'

export const inspectionControlMixin = {
  mixins: [mapboxBasicsMixin, mapboxLazyMixin, menuCardStyleMixin],
  computed: {
    ...mapState({
      dataPending: state => state.mapbox.dataPending,
      // linesShown: state => state.mapbox.linesShown,
      checkedInspTypes: state => state.mapbox.checkedInspTypes,
      checkedInspSelections: state => state.mapbox.checkedInspSelections,
      checkedInspSelectionsHidden: state => state.mapbox.checkedInspSelectionsHidden,
      checkedInspFeatureOptions: state => state.mapbox.checkedInspFeatureOptions
    }),
    ...mapGetters({
      sourceObjs: 'mapbox/sourceObjs',
      inspectionOptions: 'mapbox/inspectionOptions',
      selectedInspOptions: 'mapbox/selectedInspOptions',
      inspTypeOptions: 'mapbox/inspTypeOptions',
      inspSelectionOptions: 'mapbox/inspSelectionOptions',
      inspFeatureOptions: 'mapbox/inspFeatureOptions',
      initialInspTypeOptions: 'mapbox/initialInspTypeOptions',
      initialInspSelectionOptions: 'mapbox/initialInspSelectionOptions',
      initialInspFeatureOptions: 'mapbox/initialInspFeatureOptions',
      initialInspLineOptions: 'mapbox/initialInspLineOptions',
      initialInspOptionsMap: 'mapbox/initialInspOptionsMap',
      canToggleLines: 'mapbox/canToggleLines',
      lineOptions: 'mapbox/lineOptions',
      checkedInspLines: 'mapbox/checkedInspLines',
      inspOptionsMap: 'mapbox/inspOptionsMap'
    }),
    linesShown: {
      get () {
        return this.$store.state.mapbox.linesShown
      },
      set (value) {
        this.setLinesShown(value)
      }
    },
    checkedInspOptionsMap: {
      get () {
        return this.$store.state.mapbox.checkedInspOptionsMap
      },
      set (value) {
        this.setCheckedInspOptionsMap(value)
      }
    }
  },
  methods: {
    ...mapMutations({
      setLinesShown: 'mapbox/setLinesShown',
      setCheckedInspTypes: 'mapbox/setCheckedInspTypes',
      setCheckedInspSelections: 'mapbox/setCheckedInspSelections',
      setCheckedInspSelectionsHidden: 'mapbox/setCheckedInspSelectionsHidden',
      setCheckedInspFeatureOptions: 'mapbox/setCheckedInspFeatureOptions',
      setCheckedInspOptionsMap: 'mapbox/setCheckedInspOptionsMap'
    })
  }
}
