<template>
  <div class="mapbox-gl-control inspection-control"
       :style="[offsetStyle, posStyle]">
    <v-menu v-model="menu"
            :close-on-content-click="false"
            :nudge-width="200"
            :left="attachLeft"
            offset-x
            z-index="10">
      <!-- Button -->
      <template #activator="{ on: menu }">
        <v-tooltip :left="attachLeft"
                   :right="!attachLeft">
          <template #activator="{ on: tooltip }">
            <v-btn class="pa-1 ma-2"
                   height="auto"
                   min-width="0"
                   :disabled="!allowedActive"
                   :color="!selectedNoInspections ? 'primary darken-1' : 'white'"
                   small
                   v-on="{ ...tooltip, ...menu }"
                   @click="initLabels">
              <v-icon>search</v-icon>
            </v-btn>
          </template>
          <span>
            <slot name="tooltip">Inspection Data</slot>
          </span>
        </v-tooltip>
      </template>

      <!-- Content -->
      <v-card outlined
              flat>
        <v-card-text>
          <slot />
          <div :style="layoutStyle">
            <!-- Comparison feature active -->
            <template v-if="appFeatures.inspCtrlCompare">
              <!-- Inspection types -->
              <pibot-mapbox-inspection-select v-if="inspTypeOptions.length"
                                              :value="checkedInspTypes"
                                              :items="inspTypeOptions"
                                              label="Inspection types"
                                              class="mb-3"
                                              @change="updateCheckedInspTypes" />

              <div class="d-flex justify-space-between">
                <!-- Active selection -->
                <pibot-mapbox-inspection-select v-if="inspTypeOptions.length && inspSelectionOptions.length"
                                                :value="checkedInspSelections"
                                                :items="inspSelectionOptions"
                                                :disabled="checkedInspTypes.length === 0"
                                                label="Years"
                                                :small="true"
                                                @change="updateCheckedInspSelections" />

                <!-- Swap button -->
                <v-layout justify-center>
                  <v-btn color="primary darken-1"
                         class="my-3 mx-5"
                         small
                         @click="switchSelections">
                    <v-icon>swap_horiz</v-icon>
                  </v-btn>
                </v-layout>

                <!-- Stand-by selection -->
                <pibot-mapbox-inspection-select v-if="inspTypeOptions.length && inspSelectionOptions.length"
                                                :value="checkedInspSelectionsHidden"
                                                :items="inspSelectionOptions"
                                                :disabled="checkedInspTypes.length === 0"
                                                hint="Select stand-by items for easy comparison"
                                                label="Comparison Years"
                                                :small="true"
                                                @change="updateCheckedInspSelectionsHidden" />
              </div>

              <v-divider class="my-3"
                         v-if="inspTypeOptions.length && inspFeatureOptions.length" />

              <!-- Lines/Dots toggle -->
              <template v-if="canToggleLines">
                <p>Options</p>

                <v-switch v-model="linesShown"
                          @change="updateLinesShown"
                          label="Visualize as lines"
                          color="primary darken-1"
                          hide-details
                          dense />
              </template>

              <v-divider class="my-3"
                         v-if="canToggleLines && inspTypeOptions.length && inspFeatureOptions.length" />
            </template>

            <!-- Comparison feature inactive -->
            <template>
              <pibot-mapbox-inspection-select v-for="type in Object.keys(inspOptionsMap)"
                                              :value="checkedInspOptionsMap[type]"
                                              :key="type"
                                              :items="inspOptionsMap[type]"
                                              :label="`${type} Layers`"
                                              @change="(value) => updateCheckedInspOptionsMap(type, value)"
                                              class="mb-3" />

              <v-divider class="my-3"
                         v-if="Object.keys(inspOptionsMap).length && inspFeatureOptions.length" />

              <!-- Lines/Dots toggle -->
              <p>Options</p>
              <v-switch v-model="linesShown"
                        @change="updateLinesShown"
                        label="Visualize as lines"
                        color="primary darken-1"
                        hide-details
                        dense
                        :disabled="!checkedInspOptionsMap['Bathy Survey'] || !checkedInspOptionsMap['Bathy Survey'].length" />

              <v-divider class="my-3"
                         v-if="Object.keys(inspOptionsMap).length && inspFeatureOptions.length" />
            </template>

            <!-- Inspection Data Analyses -->
            <pibot-mapbox-inspection-select v-if="inspFeatureOptions.length"
                                            :value="checkedInspFeatureOptions"
                                            :items="inspFeatureOptions"
                                            label="Inspection Data Analyses"
                                            @change="updateCheckedInspFeatureOptions" />
          </div>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import positionStyleMixin from '@/utils/mixins/styling/positionStyle.mixin.js'
import offsetStyleMixin from '@/utils/mixins/styling/offsetStyle.mixin.js'
import mapboxBasicsMixin from '@/utils/mixins/mapbox/mapboxBasics.mixin.js'
import mapboxLazyMixin from '@/utils/mixins/mapbox/mapboxLazy.mixin.js'
import { inspectionControlMixin } from '../utils/mixins/inspectionControl.mixin'
import { mapState } from 'vuex'
import config from '@/apps/mapbox/config'

export default {
  name: 'pibot-mapbox-inspection-control',
  mixins: [
    positionStyleMixin,
    offsetStyleMixin,
    mapboxBasicsMixin,
    mapboxLazyMixin,
    inspectionControlMixin
  ],
  components: {
    'pibot-mapbox-inspection-select': () => import('./Select')
  },
  data () {
    return {
      appFeatures: config.features,
      initialized: false,
      menu: false,
      labelsInitialized: false,
      unwatchLayers: null
    }
  },
  computed: {
    ...mapState({
      layersPending: state => state.mapbox.layersPending,
      mapFilter: state => state.mapbox.mapFilter
    }),
    selectedNoInspections () {
      if (this.appFeatures.inspCtrlCompare) {
        return (
          (this.checkedInspTypes.length === 0 ||
            this.checkedInspSelections.length === 0) &&
          this.checkedInspFeatureOptions.length === 0
        )
      } else {
        let noneSelected = true
        for (const key in this.checkedInspOptionsMap) {
          if (this.checkedInspOptionsMap[key].length) {
            noneSelected = false
            break
          }
        }
        return noneSelected
      }
    },
    allowedActive () {
      return (
        this.inspectionOptions.length > 0 ||
        Object.keys(this.inspOptionsMap).length
      )
    }
  },
  methods: {
    initLabels () {
      if (this.labelsInitialized) return // No need to do it again
      if (
        !this.checkedInspTypes.length &&
        !this.checkedInspSelections.length &&
        !this.checkedInspFeatureOptions.length
      ) { return } // It is already empty

      // Save the original values
      const originTypes = JSON.parse(JSON.stringify(this.checkedInspTypes))
      const originSelections = JSON.parse(
        JSON.stringify(this.checkedInspSelections)
      )
      const originToggles = JSON.parse(
        JSON.stringify(this.checkedInspFeatureOptions)
      )
      const originCheckedInspOptionsMap = JSON.parse(
        JSON.stringify(this.checkedInspOptionsMap)
      )

      // Empty the current values
      this.setCheckedInspTypes([])
      this.setCheckedInspSelections([])
      this.setCheckedInspFeatureOptions([])
      this.checkedInspOptionsMap = []

      // Restore the original values after the component has become visible
      setTimeout(() => {
        this.setCheckedInspTypes(originTypes)
        this.setCheckedInspSelections(originSelections)
        this.setCheckedInspFeatureOptions(originToggles)
        this.checkedInspOptionsMap = originCheckedInspOptionsMap
        this.labelsInitialized = true
      }, 280) // Menu animation takes 280ms to complete
    },
    processLayers (options, selected) {
      return new Promise((resolve, reject) => {
        let i = 0
        for (const option of options) {
          const willBeVisible = selected.includes(option.value)

          if (!willBeVisible) {
            // No need to lazy-load, continue as normal
            this.setLayerVisibility(option.value, willBeVisible)
            i++
            if (i === options.length) resolve()
            continue
          }

          // Handle lazy-loading
          this.lazyLoadSource(option.value)
            .then(() => {
              this.setLayerVisibility(option.value, willBeVisible)
              i++
              if (i === options.length) {
                this.handleLinesFilter()
                resolve()
              }
            })
            .catch(error => {
              console.error(error)
            })
        }
      })
    },
    updateCheckedInspOptionsMap (type, value) {
      // Clone current Object
      const clone = JSON.parse(JSON.stringify(this.checkedInspOptionsMap))
      // Modify values
      clone[type] = value
      // Update Object
      this.checkedInspOptionsMap = clone
      // Update layers
      this.processLayers(this.inspOptionsMap[type], value)
      this.updateLinesShown()
    },
    updateCheckedInspTypes (newValue) {
      this.setCheckedInspTypes(newValue)
      if (!newValue.length) this.setCheckedInspSelections(newValue) // De-select 'selections' if current value === []
      this.processLayers(this.inspectionOptions, this.selectedInspOptions).then(
        () => {
          this.updateLinesShown()
        }
      )
    },
    updateCheckedInspSelections (newValue) {
      this.setCheckedInspSelections(newValue)
      this.processLayers(this.inspectionOptions, this.selectedInspOptions).then(
        () => {
          this.updateLinesShown()
        }
      )
    },
    updateCheckedInspSelectionsHidden (newValue) {
      this.setCheckedInspSelectionsHidden(newValue)
    },
    updateCheckedInspFeatureOptions (newValue) {
      this.setCheckedInspFeatureOptions(newValue)
      this.processLayers(this.inspFeatureOptions, newValue)
    },
    switchSelections () {
      const shownCopy = JSON.parse(JSON.stringify(this.checkedInspSelections))
      this.updateCheckedInspSelections(this.checkedInspSelectionsHidden)
      this.setCheckedInspSelectionsHidden(shownCopy)
    },
    updateLinesShown () {
      this.processLayers(this.lineOptions, this.checkedInspLines)
      this.handleLinesFilter()
    },
    handleLinesFilter () {
      // if (this.linesShown) this.filterInspectionTypes()
      // else this.filterInspectionTypes([])
    },
    // Get and set inspection data excluding exposures and freespans
    filterInspectionTypes (
      valuesToFilter = ['ES', 'EE', 'FS', 'FE', 'RDS', 'RDE', 'MS', 'ME'],
      filterBy = 'Code'
    ) {
      if (!this.allowedActive) return
      const categories = ['inspection']
      // Create an Array of layers which are within categories and are currently active
      const layerObjInCategory = this.activeLayerObjs.filter(l =>
        categories.includes(l.properties.category)
      )

      if (!layerObjInCategory.length) return

      // Get the sourceObjs which to filter
      const sourceIds = layerObjInCategory.map(l => l.source)
      const sourceObjs = sourceIds.map(id => this.map.getSource(id))

      // Filter the data for each source and set it on the map
      for (const src of sourceObjs) {
        const srcData = JSON.parse(JSON.stringify(src._data))
        srcData.features = srcData.features.filter(
          f => {
            return (
              !f.properties[filterBy] ||
              (
                (this.checkedInspLines.length && valuesToFilter.includes(f.properties[filterBy])) ||
                (!this.checkedInspLines.length && !valuesToFilter.includes(f.properties[filterBy]))
              )
            )
          }
        )
        src.setData(srcData)
      }
    }
  },
  watch: {
    mapFilter: function (mapFilter) {
      // you can't filter out clusters, so we'll have to do it manually and reset the data
      // mapFilter array to array of lines to filter out
      const linesToFilter = []
      for (let i = 1; i < mapFilter.length; i++) { linesToFilter.push(mapFilter[i][2]) }

      // eventlines
      const eventlinesFilter = mapFilter
      for (let i = 1; i < eventlinesFilter.length; i++) {
        eventlinesFilter[i][1] = 'Pipeline Name'
        eventlinesFilter[i][2] = eventlinesFilter[i][2].replace('A', 'a')
        eventlinesFilter[i][2] = eventlinesFilter[i][2].replace('B', 'b')
      }
      const eventlinesLayers = this.layerObjs.filter(l => l.id.includes('eventlines'))
      for (const eventlinesLayer of eventlinesLayers) {
        this.map.setFilter(eventlinesLayer.id, eventlinesFilter)
      }

      // get all sources, filter and reset data
      const fullInspectionData = JSON.parse(
        JSON.stringify(
          this.sourceObjs.filter(l => l.properties.id.includes('events-'))
        )
      )
      for (const inspectionData of fullInspectionData) {
        if (!inspectionData.data) continue
        inspectionData.data.features = inspectionData.data.features.filter(
          f =>
            !linesToFilter.includes(f.properties['Pipeline Name']) &&
            !linesToFilter.includes(f.properties['Pipeline Name'].toUpperCase())
        )
        this.map
          .getSource(inspectionData.properties.id)
          .setData(inspectionData.data)
      }
      const fullIliData = JSON.parse(
        JSON.stringify(
          this.sourceObjs.filter(l => l.properties.id.includes('ili-'))
        )
      )
      for (const iliData of fullIliData) {
        if (!iliData.data) continue
        iliData.data.features = iliData.data.features.filter(
          f =>
            !linesToFilter.includes(f.properties['Pipeline Name']) &&
            !linesToFilter.includes(f.properties['Pipeline Name'].toUpperCase())
        )
        this.map.getSource(iliData.properties.id).setData(iliData.data)
      }
      const fullCpData = JSON.parse(
        JSON.stringify(
          this.sourceObjs.filter(l => l.properties.id.includes('cp-'))
        )
      )
      for (const cpData of fullCpData) {
        if (!cpData.data) continue
        cpData.data.features = cpData.data.features.filter(
          f =>
            !f.properties.Pipeline ||
            (
              !linesToFilter.includes(f.properties.Pipeline) &&
              !linesToFilter.includes(f.properties.Pipeline.toUpperCase())
            )
        )
        this.map.getSource(cpData.properties.id).setData(cpData.data)
      }
    }
  },
  created () {
    /**
     * Set initially 'visible' options as selected on init
     * @see https://vuejs.org/v2/api/#vm-watch
     * @see https://stackoverflow.com/questions/46987893/vuejs2-how-can-i-destroy-a-watcher#answer-46988117
     */
    this.unwatchLayers = this.$watch(
      'layersPending',
      layersPending => {
        if (!this.initialized && !layersPending) {
          this.setCheckedInspTypes(this.initialInspTypeOptions)
          this.setCheckedInspSelections(this.initialInspSelectionOptions)
          this.setCheckedInspFeatureOptions(this.initialInspFeatureOptions)

          /**
           * Set 'Lines' toggle to initial value
           */
          if (this.initialInspLineOptions.length > 0) this.linesShown = true
          if (Object.keys(this.initialInspOptionsMap).length > 0) { this.checkedInspOptionsMap = this.initialInspOptionsMap }

          this.initialized = true

          // Unwatch after init
          if (this.unwatchLayers) this.unwatchLayers()
          else console.error("unable to 'unwatch' 'layersPending'")
        }
      },
      {
        deep: true
      }
    )
  }
}
</script>
